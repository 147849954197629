import React, { Component } from 'react';
import ThumbnailCards from '../components/ThumbnailCards';

class TwoDThreeD extends Component {
  render() {
    return <ThumbnailCards dataPath='/2d3d' />;
  }
}

export default TwoDThreeD;
